<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
      <v-card-title class="text-body-1 primary--text justify-center">
        ¿Estás seguro de que quieres eliminar?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="deleteItemConfirm()">Si</v-btn>
        <v-btn color="blue" text @click="closeDelete()">NO</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { $EventBus } from "@/main";
export default {
  name: "DeleteActivityDialog",
  props: {
    activity: Object,
  },
  data: () => ({
    dialogDelete: false,
  }),
  watch: {
    activity(val) {
      if (val && val.id) {
        this.dialogDelete = true;
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    deleteItemConfirm() {
      this.$store
          .dispatch("activities/deleteActivity", this.activity.id)
          .then(() => {
            $EventBus.$emit("showSnack", {
              color: "green",
              text: "Se ha eliminado satisfactoriamente",
            });
          });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {});
    },
  },
};
</script>
