import CreateActivityDialog from "@/components/applications/activity/CreateActivityDialog.vue";
import EditActivityDialog from "@/components/applications/activity/EditActivityDialog.vue";
import DeleteActivityDialog from "@/components/applications/activity/DeleteActivityDialog.vue";
import SearchInput from "@/components/global/SearchInput.vue";

export default {
  name: "Activities",

  data: () => ({
    search: "",
    headers: [
      {
        text: "Pagina",
        value: "page_name",
        align: "start",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Nombre",
        value: "name",
        align: "start",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Descripción",
        value: "description",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Estado",
        value: "active",
        align: "center",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        class: "primary--text",
      },
    ],
    editedItem: {},
    deletedItem: {},
    currentPage: 1,
    appSelected: null,
    moduleSelected: null,
    pageSelected: null,
    timeout: null,
    limit: 5,
    rowsPerPage: [{ value: 5 }, { value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }]
  }),

  computed: {
    applications() {
      return this.$store.getters["applications/getApplicationsList"];
    },

    modules() {
      return this.$store.getters["modules/getModulesList"];
    },

    pages() {
      return this.$store.getters["pages/getPagesList"];
    },

    activities() {
      return this.$store.getters["activities/getActivities"];
    },

    paginationPages() {
      return this.$store.getters["activities/getPaginationPages"];
    },

    widthPage() {
      return this.$vuetify.breakpoint.width;
    },

    isLoading() {
      return this.$store.state.activities.isLoading;
    },
  },

  watch: {},

  created() {
    this.$store.dispatch("activities/getActivities", { page: 1, limit: 5 });
    this.$store.dispatch("applications/getApplicationsList");
    this.$store.dispatch("activities/updateCurrentPage", 1);
  },

  methods: {
    editItem(item) {
      this.editedItem = { ...item };
    },

    deleteItem(item) {
      this.deletedItem = { ...item };
    },

    listenApplication() {
      this.currentPage = 1;
      this.moduleSelected = null;
      this.pageSelected = null;
      this.search = "";
      this.$store.dispatch("modules/getModulesList", {
        app_id: this.appSelected,
      });
      this.$store.dispatch("activities/getActivities", {
        page: 1,
        app_id: this.appSelected,
      });
    },

    listenModule() {
      this.currentPage = 1;
      this.pageSelected = null;
      this.search = "";
      this.$store.dispatch("pages/getPagesList", {
        module_id: this.moduleSelected,
      });
      this.$store.dispatch("activities/getActivities", {
        page: 1,
        app_id: this.appSelected,
        module_id: this.moduleSelected,
      });
    },

    listenPage() {
      this.$store.dispatch("activities/getActivities", {
        page: 1,
        app_id: this.appSelected,
        module_id: this.moduleSelected,
        page_id: this.pageSelected,
      });
    },

    listenPagination() {
      this.$store.dispatch("activities/getActivities", {
        page: this.currentPage,
        app_id: this.appSelected,
        module_id: this.moduleSelected,
        page_id: this.pageSelected,
        search: this.search,
        limit: this.limit
      });
      this.$store.dispatch("activities/updateCurrentPage", this.currentPage);
    },

    resetFilter() {
      this.search = "";
      this.pageSelected = null;
      this.moduleSelected = null;
      this.appSelected = null;
      this.currentPage = 1;
    },
  },
  components: {
    CreateActivityDialog,
    EditActivityDialog,
    DeleteActivityDialog,
    SearchInput,
  },
};
