<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="my-1 text-none"
        v-bind="attrs"
        v-on="on"
        rounded
      >
        <v-icon left> mdi-plus </v-icon>
        Nueva Actividad
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-body-1 primary--text">Nueva Actividad</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <validation-alert :validationErrors="validationErrors" />
          <v-form ref="form" v-model="isValidFormDialog" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.app_id"
                  :items="applications"
                  :rules="applicationRules"
                  item-text="name"
                  item-value="id"
                  label="Aplicación"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.module_id"
                  :items="modules"
                  :rules="moduleRules"
                  v-if="editedItem.app_id"
                  item-text="name"
                  item-value="id"
                  label="Módulo"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.page_id"
                  :items="pages"
                  :rules="pageRules"
                  v-if="editedItem.module_id"
                  item-text="name"
                  item-value="id"
                  label="Página"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="nameRules"
                  label="Nombre"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.description"
                  name="description"
                  label="Descripción"
                  :rules="descriptionRules"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey lighten-1" text @click="close()"> Cancelar </v-btn>
        <v-btn
          color="blue darken-1"
          :loading="isLoading"
          :disabled="isLoading"
          @click="save()"
          text
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationAlert from "@/components/global/ValidationAlert.vue";
import { $EventBus } from "@/main";
export default {
  name: "CreateActivityDialog",

  props: {},

  data: () => ({
    dialog: false,
    isValidFormDialog: false,
    isLoading: false,
    applicationRules: [(v) => !!v || "Campo requerido"],
    moduleRules: [(v) => !!v || "Campo requerido"],
    pageRules: [(v) => !!v || "Campo requerido"],
    nameRules: [(v) => !!v || "Campo requerido"],
    descriptionRules: [(v) => !!v || "Campo requerido"],
    editedItem: {
      app_id: "",
      module_id: "",
      page_id: "",
      name: "",
      description: "",
      active: true,
    },
    defaultItem: {
      app_id: "",
      module_id: "",
      page_id: "",
      name: "",
      description: "",
      active: true,
    },
  }),

  computed: {
    applications() {
      return this.$store.getters["applications/getApplicationsList"];
    },
    modules() {
      return this.$store.getters["modules/getModulesList"];
    },
    pages() {
      return this.$store.getters["pages/getPagesList"];
    },
    validationErrors() {
      return this.$store.getters["activities/getValidationErrors"];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
      if (val) {
        //this.$store.dispatch("applications/getApplicationsList");
      }
    },
    "editedItem.app_id"(val) {
      if (val) {
        this.$store.dispatch("modules/getModulesList", {
          app_id: this.editedItem.app_id,
        });
      }
    },
    "editedItem.module_id"(val) {
      if (val) {
        this.$store.dispatch("pages/getPagesList", {
          module_id: this.editedItem.module_id,
        });
      }
    },
  },

  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.$store.dispatch("activities/cleanValidationErrors");
        this.$refs.form.resetValidation();
      });
    },

    showSnack(color = "", text = "") {
      $EventBus.$emit("showSnack", { color, text });
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          await this.$store.dispatch(
            "activities/saveActivity",
            this.editedItem
          );
          this.showSnack("green", "Se ha registrado satisfactoriamente");
          this.$emit('resetFilter');
          this.close();
        } catch (error) {}
        this.isLoading = false;
      }
    },
  },
  components: {
    ValidationAlert,
  },
};
</script>
